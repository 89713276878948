<template>
  <section class="about-v2-block">
    <div class="container-small">
      <div class="about-v2-block__content">
        <h2 v-if="title" v-html="title" class="about-v2-block__title"/>
        <div
          v-if="message"
          class="about-v2-block__message cms-block-stub"
          :class="{
            '--full': isFull,
            '--overflow': !isFull && isVisibleMore,
          }"
        >
          <div
            ref="refElementMessage"
            v-html="message"
          />
        </div>
        <div
          v-if="isVisibleMore"
          class="about-v2-block__more"
        >
          <button
            class="btn btn-primary btn-outline"
            @click="changeFull"
          >
            {{ $t(isFull ? 'common.collapse' : 'common.show_more') }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { ComputedRef } from 'vue';
const props = defineProps<IBlockDefaultProps>();

const title: ComputedRef<string> = computed(() => {
  return props.values?.title || ""
});
const message: ComputedRef<string> = computed(() => {
  return props?.values?.body || ""
});

const refElementMessage: Ref<HTMLElement | undefined> = ref();
const isFull: Ref<boolean> = ref(false);
const isVisibleMore: Ref<boolean> = ref(false);
const changeFull = () => {
  isFull.value = !isFull.value;
};
const initVisibleMore = () => {
  if (!refElementMessage.value) {
    return
  }

  const height = refElementMessage.value.getBoundingClientRect().height;
  if (height <= 343) {
    return;
  }

  isVisibleMore.value = true;
};

onMounted(() => {
  initVisibleMore();
});
</script>

<style scoped lang="scss">
.about-v2-block {
  margin: 50px 0;
  box-sizing: border-box;
}
.about-v2-block__content {
  padding: 26px 40px;
  border-radius: 32px;
  box-sizing: border-box;
  border: 1px solid hsla(var(--black)/0.1);
  background: hsla(var(--main-section-bg)/1);
  box-shadow: 0px 8px 16px 0px hsla(var(--black)/0.05);
}
.about-v2-block__title {
  margin-bottom: 24px;

  font-size: 36px;
  line-height: 1;
  font-weight: 600;
  text-align: center;
  color: hsla(var(--dark-gray-tx)/1);
}
.about-v2-block__message {
  color: hsla(var(--link-tx)/1);
  max-height: 343px;
  overflow: hidden;

  &.--full {
    max-height: initial;
  }
  &.--overflow {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      left: 0; right: 0;
      bottom: 0;
      height: 100px;
      pointer-events: none;
      background: linear-gradient(180deg, hsla(var(--main-section-bg)/0) 0%, hsla(var(--main-section-bg)/1) 100%);
    }
  }
}
.about-v2-block__more {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

@media (max-width: 859px) {
  .about-v2-block__content {
    padding: 20px 12px;
    padding-bottom: 12px;
  }
  .about-v2-block__title {
    margin-bottom: 18px;
    font-size: 24px;
  }
}
</style>
